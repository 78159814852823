<template>
  <div class="overall">
    <!-- <div class="main-Title bgff">
      <h2>Overall</h2>
    </div> -->
    <div class="mainBox">
      <el-card shadow="hover" class="mb10 list-filter">
        <el-form :model="filter" size="mini">
          <el-row :gutter="10">
            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="5">
              <el-form-item label="Affiliate ID" label-width="80px">
                <el-input v-model="filter.affiliateIds" placeholder="1" class="w100"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="5">
              <el-form-item label="Affiliate status" label-width="100px">
                <el-select v-model="filter.status" placeholder="active" class="w100">
                  <el-option
                    v-for="item in statusOption"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="5">
              <el-form-item label="Affiliate trafficType" label-width="130px">
                <el-select v-model="filter.trafficType" class="w100">
                  <el-option
                    v-for="item in payoutTypeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="5">
              <el-form-item label="Affiliate name" label-width="100px">
                <el-input
                  v-model="filter.affiliateName"
                  class="w100"
                  placeholder="your searching aff regex here"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="4" align="right">
              <el-form-item>
                <el-button type="primary" @click="checkList(1)">In Browser</el-button>
                <el-button type="primary" icon="el-icon-plus" @click="addClick">Create</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <div>
          <el-table
            v-loading="loading"
            border
            stripe
            :data="dataList"
            style="width: 100%"
            size="mini"
            highlight-current-row
          >
            <el-table-column label="Id" align="center" min-width="60" fixed>
              <template slot-scope="scope">
                <span @click="detailClick(scope.row)" class="cor337ab7">{{ scope.row.id }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="Affiliate Name"
              prop="company"
              align="center"
              min-width="130"
              fixed
            >
              <template slot-scope="scope">
                <span @click="detailClick(scope.row)" class="cor337ab7">{{
                  scope.row.company
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="Affiliate Status"
              prop="status"
              align="center"
              min-width="130"
            ></el-table-column>
            <el-table-column
              label="Postback Url"
              prop="postbackUrl"
              min-width="120"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              label="install pb"
              prop="postbackInstallUrl"
              min-width="120"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              label="event pb"
              prop="postbackEventUrl"
              min-width="120"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              label="Description"
              prop="description"
              align="center"
              min-width="100"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              label="payoutPercent"
              prop="payoutPercent"
              align="center"
              min-width="100"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              label="percentEnable"
              prop="percentEnable"
              align="center"
              min-width="100"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              label="Token Code"
              prop="code"
              align="center"
              min-width="110"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              label="type"
              prop="type"
              align="center"
              min-width="100"
            ></el-table-column>
            <el-table-column
              label="automatic"
              prop="automatic"
              align="center"
              min-width="100"
            ></el-table-column>
            <el-table-column
              label="Adjusted"
              prop="adjusted"
              align="center"
              min-width="100"
            ></el-table-column>
            <el-table-column label="Adjust Rate" prop="adjustRate" align="center" min-width="100">
              <template slot-scope="scope"> {{ scope.row.adjustRate }}% </template>
            </el-table-column>
            <el-table-column
              label="低价价扣量比率"
              prop="lowAdJustRate"
              align="center"
              min-width="100"
            >
              <template slot-scope="scope"> {{ scope.row.lowAdJustRate }}% </template>
            </el-table-column>
            <el-table-column
              label="高价扣量比率"
              prop="highAdJustRate"
              align="center"
              min-width="100"
            >
              <template slot-scope="scope"> {{ scope.row.highAdJustRate }}% </template>
            </el-table-column>
            <el-table-column
              label="提升利润($)"
              prop="increaseProfit"
              align="center"
              min-width="100"
            ></el-table-column>
            <el-table-column
              label="随机比率"
              prop="randomSampling"
              align="center"
              min-width="100"
            ></el-table-column>
            <el-table-column
              label="Delay time"
              prop="outHourAdjust"
              align="center"
              min-width="100"
            ></el-table-column>
            <el-table-column
              label="Track Domain"
              prop="trackDomain"
              align="center"
              min-width="120"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column label="affPayout" prop="payout" align="center" min-width="120">
              <template slot-scope="scope">
                {{ scope.row.payout }}
                <!-- <i class="el-icon-edit" @click="handlePayoutClose(scope.row)"></i> -->
              </template>
            </el-table-column>
            <el-table-column
              label="operator"
              prop="createBy"
              align="center"
              min-width="100"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              label="operation time"
              prop="createTime"
              align="center"
              min-width="130"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column label="Option" align="center" min-width="100" fixed="right">
              <template slot-scope="scope">
                <el-link type="primary" @click="updateAffiliateClick(scope.row)" :underline="false"
                  >Update</el-link
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="align-c">
            <pagination
              class="block pagePanel"
              :pageTotal="total"
              @handleSizeChange="handleSizeChange"
              @handleCurrentChange="handleCurrentChange"
              :pageSize="pageSize"
              :currentPage="currentPage"
            ></pagination>
          </div>
        </div>
      </el-card>
    </div>

    <el-dialog title="提示" :visible.sync="payoutdialogVisible" width="30%">
      <el-input v-model="affpayout" placeholder="add adjust rate"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="payoutdialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateAffPayout()">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 模块框 -->
    <el-dialog
      :title="dialogType === 'new' ? 'Affiliate' : 'Update'"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :append-to-body="true"
      :before-close="handleClose"
    >
      <el-form
        label-position="right"
        label-width="170px"
        ref="addAffiliate"
        :model="affiliate"
        :rules="rules"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="Name:" prop="affiliateName">
              <el-input v-model="affiliate.affiliateName" placeholder="Name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Description:" prop="description">
              <el-input v-model="affiliate.description" placeholder="description"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="Postaback Url:">
              <el-input
                v-model="affiliate.postbackUrl"
                placeholder="postback url here..."
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="install pb Url:">
              <el-input
                v-model="affiliate.postbackInstallUrl"
                placeholder="install pb here..."
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="event pb url:">
              <el-input
                v-model="affiliate.postbackEventUrl"
                placeholder="event pb here..."
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="Level:">
              <el-select v-model="affiliate.level" filterable placeholder="Level" class="w100">
                <el-option
                  v-for="item in levelOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="Traffic Type:" prop="trafficType">
              <el-select
                v-model="affiliate.trafficType"
                filterable
                placeholder="Traffic Type"
                class="w100"
              >
                <el-option
                  v-for="item in trafficType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Track Domain:">
              <el-input
                v-model="affiliate.trackDomain"
                placeholder="track domain here..."
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="Adjusted Enable:" prop="adjusted">
              <el-select
                v-model="affiliate.adjusted"
                filterable
                placeholder="Adjusted Enable"
                class="w100"
              >
                <el-option
                  v-for="item in offerMonitor"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="adjust rate:" prop="adjustRate">
              <el-input v-model="affiliate.adjustRate" placeholder="扣量比例">
                <template slot="append">%</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="Out Hour Adjust:">
              <el-input
                v-model="affiliate.outHourAdjust"
                placeholder="outHourAdjust url here..."
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Traffic Tag:" prop="trafficTag">
              <el-select
                v-model="affiliate.trafficTag"
                clearable
                filterable
                placeholder="status"
                class="w100"
              >
                <el-option
                  v-for="item in tagOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row> </el-row>
        <el-row>
          <el-col :span="12" v-if="dialogType !== 'new'">
            <el-form-item label="Status:" prop="status">
              <el-select v-model="affiliate.status" filterable placeholder="status" class="w100">
                <el-option
                  v-for="item in statusOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="type:" prop="type">
              <el-select v-model="affiliate.type" filterable placeholder="type" class="w100">
                <el-option
                  v-for="item in typeOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="14" v-for="(email, index) in affiliate.emails" :key="email.key">
            <el-form-item
              :label="'email-' + index"
              :prop="'emails.' + index + '.value'"
              :rules="{
                required: true,
                message: 'email is null',
                trigger: 'blur',
              }"
            >
              <el-input v-model="email.value" style="width: 85%"></el-input>
              <el-button type="danger" size="mini" @click.prevent="removeDomain(email)"
                >删除</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="优化利润:" prop="increaseProfit">
              <el-input-number
                v-model.number="affiliate.increaseProfit"
                placeholder="想要减少利润"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="取样比率:" prop="randomSampling">
              <el-input
                v-model="affiliate.randomSampling"
                placeholder="取样比率  here..."
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="判定单价:" prop="judgePayout">
              <el-input
                v-model.trim="affiliate.judgePayout"
                placeholder="判定是否是高价低价payout..."
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="payoutPercent:" prop="payoutPercent">
              <el-input
                v-model="affiliate.payoutPercent"
                :disabled="!!affiliate.payout"
                placeholder="payoutPercent here..."
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="affpayout:" prop="payout">
              <el-input
                v-model="affiliate.payout"
                :disabled="!!affiliate.payoutPercent"
                placeholder="affpayout  here..."
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <el-form-item label="percentEnable:" prop="percentEnable">
              <el-radio-group v-model="affiliate.percentEnable">
                <el-radio label="enable">enable(开启渠道扣价)</el-radio>
                <el-radio label="disable">disable(关闭渠道扣价）</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24">
            <el-form-item label="automatic:" prop="automatic">
              <el-radio-group v-model="affiliate.automatic">
                <el-radio label="automatic">automatic(自动)</el-radio>
                <el-radio label="artificial">artificial(人工通知）</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24" align="right">
            <el-button size="small" @click="handleClose">取 消</el-button>
            <el-button size="small" @click="addDomain('add')">新增email</el-button>
            <el-button size="small" type="primary" @click="submitClick()">确 定</el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
  import {
    getAffiliateList,
    insertAffiliate,
    updateAffiliate,
    updatePayout,
  } from 'api/affiliate/overall';
  import Pagination from '@/components/pagination';
  import { myMixin } from '@/mixins/mixins.js';
  import { cloneDeep } from 'lodash-es';
  import { getAllTags } from '@/api/affiliate/tag';
  var clickTimer = null;

  const defaultAffiliate = {
    affiliateName: '',
    description: '',
    level: '',
    trafficType: '',
    postbackUrl: '',
    postbackInstallUrl: '',
    postbackEventUrl: '',
    trackDomain: '',
    adjusted: '',
    outHourAdjust: '',
    status: '',
    email: '',
    emails: [
      {
        value: '',
      },
    ],
  };
  export default {
    mixins: [myMixin],
    components: {
      Pagination,
    },
    data() {
      return {
        loading: false,
        pageSize: 20,
        currentPage: 1,
        total: null,
        payoutdialogVisible: false,
        dialogVisible: false,
        dialogType: 'new',
        affiliate: Object.assign({}, defaultAffiliate),
        affiliatePayout: {},
        affpayout: '',
        rules: {
          affiliateName: [
            {
              required: true,
              message: 'please write name',
              trigger: 'blur',
            },
          ],
          adjustRate: [
            { validator: this.validateIntegerValue, trigger: 'blur' },
            { required: false, message: '请输入合适的数值', trigger: 'blur' },
          ],
          trafficType: [
            {
              required: true,
              message: 'please select traffic type',
              trigger: 'blur',
            },
          ],
          adjusted: [
            {
              required: true,
              message: 'please select adjusted',
              trigger: 'blur',
            },
          ],
          description: [
            {
              required: true,
              message: 'please write description',
              trigger: 'blur',
            },
          ],
          status: [
            {
              required: true,
              message: 'please select status',
              trigger: 'blur',
            },
          ],
          type: [
            {
              required: true,
              message: 'please select type',
              trigger: 'blur',
            },
          ],
        },
        filter: {
          affiliateId: null,
          status: 'active',
          payoutType: null,
          affiliateName: null,
        },
        statusOption: [
          {
            label: 'ACTIVE',
            value: 'active',
          },
          {
            label: 'PENDING',
            value: 'pending',
          },
          {
            label: 'PAUSED',
            value: 'paused',
          },
        ],
        typeOption: [
          {
            label: 'vba',
            value: 'vba',
          },
          {
            label: 'emu',
            value: 'emu',
          },
          {
            label: 'other',
            value: 'other',
          },
          {
            label: 'ddj',
            value: 'ddj',
          },
        ],
        autoOption: [
          {
            label: 'auto',
            value: 'auto',
          },
          {
            label: 'artificial',
            value: 'artificial',
          },
        ],
        status: [
          {
            label: 'ACTIVE',
            value: 'ACTIVE',
          },
          {
            label: 'PENDING',
            value: 'PENDING',
          },
        ],
        levelOption: [
          {
            label: 'Direct',
            value: 50,
          },
          {
            label: 'In-app',
            value: 40,
          },
          {
            label: 'Network',
            value: 30,
          },
          {
            label: 'MediaBuy',
            value: 20,
          },
          {
            label: 'Incent',
            value: 10,
          },
        ],
        trafficType: [
          {
            label: 'CPI',
            value: 'CPI',
          },
          {
            label: 'CPA',
            value: 'CPA',
          },
          {
            label: 'CPL',
            value: 'CPL',
          },
        ],
        offerMonitor: [
          {
            label: 'DISABLE',
            value: 'DISABLE',
          },
          {
            label: 'ENABLE',
            value: 'ENABLE',
          },
        ],
        payoutTypeOptions: [
          {
            label: 'ALL',
            value: null,
          },
          {
            label: 'CPI',
            value: 'CPI',
          },
          {
            label: 'CPA',
            value: 'CPA',
          },
        ],
        dataList: [],
        tagOption: [],
      };
    },
    mounted() {
      this.checkList();
      this.getTagList();
    },
    methods: {
      validateIntegerValue(rule, value, callback) {
        if (value) {
          const integerValue = parseInt(value, 10);
          if (Number.isInteger(integerValue) && integerValue >= 0 && integerValue <= 100) {
            callback();
          } else {
            callback(new Error('请输入合适的数值'));
          }
        } else {
          callback();
        }
      },
      checkList(page) {
        this.loading = true;
        if (page) {
          this.currentPage = page;
        }
        let param = {
          ...this.filter,
          page: this.currentPage,
          pageSize: this.pageSize,
        };
        getAffiliateList(param).then((response) => {
          if (response.code === 200) {
            this.loading = false;
            this.dataList = response.result;
            this.total = response.total;
          } else {
            this.loading = false;
            this.$message.error(response.message);
          }
        });
      },
      addClick() {
        this.affiliate = Object.assign({}, defaultAffiliate);
        delete this.affiliate.status;
        this.dialogType = 'new';
        this.dialogVisible = true;
      },
      handleSizeChange(size) {
        this.pageSize = size;
        this.handleCurrentChange(1);
      },
      handleCurrentChange(currentPage) {
        this.currentPage = currentPage;
        this.checkList();
      },
      updateAffiliateClick(row) {
        this.dialogType = 'edit';
        this.dialogVisible = true;
        if (row.email) {
          row.emails = JSON.parse(row.email);
        } else {
          row.emails = [
            {
              value: '',
            },
          ];
        }
        this.affiliate = Object.assign(cloneDeep(row), { affiliateName: row.company });
      },
      addDomain(data) {
        if (data === 'add') {
          this.affiliate.emails.push({
            value: '',
            key: Date.now(),
          });
        } else {
          this.affiliate.emails.push({
            value: '',
            key: Date.now(),
          });
        }
      },
      removeDomain(item) {
        var index = this.affiliate.emails.indexOf(item);
        if (index !== -1) {
          this.affiliate.emails.splice(index, 1);
        }
      },
      handleClose() {
        this.dialogVisible = false;
        this.$refs.addAffiliate.clearValidate();
      },
      submitClick() {
        this.$refs.addAffiliate.validate((valid) => {
          if (valid) {
            // const payoutPercent = this.affiliate.payoutPercent;
            // this.checkDouble(payoutPercent);
            if (this.dialogType === 'new') {
              insertAffiliate({ ...this.affiliate }).then((response) => {
                if (response.code == 200) {
                  this.$message({
                    message: 'Add Update',
                    type: 'success',
                  });
                  this.checkList();
                  this.dialogVisible = false;
                } else {
                  this.$message.error('Add Error:' + response.message);
                }
              });
            } else if (this.dialogType === 'edit') {
              updateAffiliate({ ...this.affiliate }).then((response) => {
                if (response.code === 200) {
                  this.$message({
                    message: 'Update Success',
                    type: 'success',
                  });
                  this.checkList();
                  this.dialogVisible = false;
                } else {
                  this.$message.error('Update Error:' + response.message);
                }
              });
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      checkDouble(value) {
        // 验证是否为double类型
        const isDouble = /^-?\d+(\.\d+)?$/.test(value);
        if (!isDouble) {
          this.$message.error('payoutPercent not num');
          return;
        }
      },
      updateAffPayout() {
        this.affiliatePayout.payout = this.affpayout;
        updatePayout({ ...this.affiliatePayout }).then((response) => {
          if (response.code === 200) {
            this.$message({
              message: 'Update Success',
              type: 'success',
            });
            this.checkList();
            this.payoutdialogVisible = false;
          } else {
            this.$message.error('Update Error:' + response.messsage);
          }
        });
      },
      handlePayoutClose(row) {
        this.affpayout = row.payout;
        this.payoutdialogVisible = true;
        this.affiliatePayout.id = row.id;
        console.log(this.affiliatePayout);
      },
      detailClick(row) {
        if (clickTimer) {
          window.clearTimeout(clickTimer);
          clickTimer = null;
        }
        clickTimer = window.setTimeout(() => {
          this.$nextTick(() => {
            console.log(row, 'row');
            const { href } = this.$router.resolve({
              path: '/affiliate/detail',
              query: {
                affiliateId: row.id,
                affiliateName: row.company,
                status: row.status,
              },
            });
            window.open(href, '_blank');
          });
        }, 300);
      },
      getTagList() {
        getAllTags()
          .then((resp) => {
            if (resp.code === 200) {
              this.tagOption = resp.result?.map((item) => ({
                label: item.trafficTag,
                value: item.trafficTag,
              }));
            } else {
              this.tagOption = [];
            }
          })
          .catch(() => {
            this.tagOption = [];
          });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .cor337ab7 {
    color: #337ab7;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
</style>
